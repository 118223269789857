import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import Scroll from 'vue-seamless-scroll'
import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'
import Vuex from 'vuex'
// import i18n from '@/language'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(VueResize).use(Vuex).use(Scroll).use(ElementPlus).use(router).mount('#app')


