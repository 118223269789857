import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
import pageFooter from "../src/components/FooterComponent.vue";
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_vue3_seamless_scroll = _resolveComponent("vue3-seamless-scroll");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_vue3_seamless_scroll, {
        class: "scroll_content"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view), _createVNode(pageFooter, {
          class: "footer"
        })]),
        _: 1
      })]);
    };
  }
};