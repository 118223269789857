import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: '/',
         component:  ()=>import('../views/RootPage.vue')
    },
    {
        path: '/en/about/privacy_policy',
        name: '/en/about/privacy_policy',
        component: ()=>import('../views/other/en/PrivacyPolicyPage.vue')
    },
    {
        path: '/en/about/term_of_service',
        name: '/en/about/term_of_service',
        component: ()=>import('../views/other/en/ServicePage.vue')
    },
    {
        path: '/en/about/test',
        name: '/en/about/test',
        component: ()=>import('../views/other/en/TestPage.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    // mode: 'history',
    routes
})

export default router
